import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div id="welcome-header">
        Welcome!
        </div>
        <p>
          This site is under construction. While you're here, enjoy some music. See you soon!
        </p>
          <iframe src="https://open.spotify.com/embed/playlist/5LFZJ7tNsrKbeDrMfW06lf" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </header>
    </div>
  );
}

export default App;
